<script lang="ts" setup>
import type { InitPresetPackageData } from "@/types";

const props = defineProps<{
	isShowEmailNotificationMessage?: boolean;
	isLogin: boolean;
	showSidebar: boolean;
	isShowRedeem?: boolean;
	isCoinDrops?: boolean;
	isDepositStreak?: boolean;
	isScratchCards?: boolean;
	isShowAffiliate?: boolean;
	isBingoLottery?: boolean;
	isShowBadgeActiveRaces?: boolean;
	bannerPromo?: InitPresetPackageData;
}>();
const emit = defineEmits<{ (event: "toggleSidebar"): void }>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const { t } = useT();
const { open } = useNlcModals();
const loginGuard = useLoginGuard();
const { logoutUser } = useLogout();
const { bonusesData } = useBonusesData();
const { prizeVaultIsActive } = useFeaturesIsActive();
const { handleSupportButtonClick } = useSupportChat();
const { isCanPlay } = useBingoData({ immediate: false });
const { SKIN_CAMP } = useScretchCardData({ immediate: false });
const DIVIDER_COLOR = "radial-gradient(circle at center, rgba(74, 91, 145, 0.3) 1%, var(--neutral-10) 100% )";

const getReasonType = computed(() => bonusesData.value?.dailyWheel?.reasonType);
const getReasonValue = computed(() => bonusesData.value?.dailyWheel?.reasonValue as "phone" | "balance" | "time");
const { durationLeft: wheelTimer, reset: resetWheel } = useCountdown({
	timestamp: getReasonValue.value || "",
	formatToken: "H[h ] m[m ] ss[s]"
});

watch(
	() => getReasonValue.value,
	() => {
		resetWheel(getReasonValue.value || "");
	}
);

const handleBestDealCash = () => {
	loginGuard({
		success: () => {
			window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props.bannerPromo?.id}/promoOffer/`);
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
const handleLuckyWheelOpen = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDailyWheel");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
const handleBingoOpen = () => {
	loginGuard({
		success: () => {
			if (isCanPlay.value) {
				open("LazyOModalBingoPlay");
				return;
			}
			open("LazyOModalBingoWelcome");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
const handleWheelOfWinsOpen = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "wheel_of_wins_progress_bar",
		location: "menu"
	});
};
const handlePrizeVaultOpen = () => {
	open("LazyOModalPrizeVaultPlay");
};
const handlePromoClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "promotions",
		location: "menu"
	});
};
const handleOpenSupportClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: "support"
	});
	handleSupportButtonClick();
	emit("toggleSidebar");
};
const handlelogOutClick = async () => {
	await logoutUser();
	navigateTo("/", { external: true });
	localStorage.removeItem("showedDailyWheelPopup");
	localStorage.removeItem("showVerificationAlarm");
	emit("toggleSidebar");
};
const handleRedeemClick = () => {
	window?.$store?.gaCash?.deposit?.({
		location: "redeem",
		step: "payments_method"
	});
	window?.$cash?.$router?.push?.("/cash/redeem/");
};

const promoOfferBadge = computed(() => (props.bannerPromo?.badgeLabel ? JSON.parse(props.bannerPromo.badgeLabel) : ""));
const promoOfferPercent = computed(() =>
	promoOfferBadge.value?.percent ? `+${promoOfferBadge.value?.percent} more` : ""
);
const promoOfferTitle = computed(() => promoOfferBadge.value?.labelText);
const promoOfferBackground = computed(
	() => promoOfferBadge.value?.menuBackground || "linear-gradient(90deg, #7b4fdc 0%, rgba(123, 79, 220, 0) 100%)"
);
const promoOfferImage = computed(() =>
	props.bannerPromo?.imageMenu
		? `${baseImageUrl}${props.bannerPromo?.imageMenu}`
		: "/nuxt-img/insufficient-funds/mini-offer.png"
);
</script>

<template>
	<Transition name="fade" appear>
		<AOverlay
			v-if="showSidebar"
			class="sidebar-overlay"
			bgColor="rgba(var(--neutral-5-rgb), 0.64)"
			:modifiers="['fixed']"
			@click="$emit('toggleSidebar')"
		/>
	</Transition>

	<Transition name="slide" data-tid="menu-container" appear>
		<aside v-if="showSidebar" :class="[{ 'header-padding': isShowEmailNotificationMessage }]">
			<div class="sidebar-top">
				<nav class="aside-menu" data-tid="menu-links" @click="$emit('toggleSidebar')">
					<div class="banners-mini">
						<LazyOSidebarVipClub class="aside-menu-banner" />
						<div class="aside-menu-banner wheel" @click="handleLuckyWheelOpen()">
							<div class="inner-content">
								<MDailyWheel class="icon" />
								<AText class="text-neutral-100" :modifiers="['medium']">{{ t("Lucky Wheel") }}</AText>
							</div>
							<AButton :variant="getReasonType === 'time' ? 'disabled' : 'outline'" size="xs">
								<AText
									class="text-cannes"
									:size="getReasonType === 'time' ? 14 : 12"
									:modifiers="['uppercase', 'bold']"
								>
									{{ getReasonType === "time" ? wheelTimer : "Spin now" }}
								</AText>
							</AButton>
						</div>
						<div v-if="bannerPromo || !isLogin" class="aside-menu-banner bonus" @click="handleBestDealCash()">
							<div class="inner-content">
								<NuxtImg
									class="icon"
									:src="promoOfferImage"
									loading="lazy"
									alt="mini-offer"
									width="76"
									height="77"
									format="avif"
								/>
								<AText class="text-neutral-100" :modifiers="['medium']">
									<span class="text-tertiary-80">{{ promoOfferTitle || t("Best deal") }}</span>
									{{ promoOfferPercent }}
								</AText>
							</div>
							<AButton variant="primary" size="xs">
								<AText class="text-cannes" :size="12" :modifiers="['uppercase', 'bold']"> {{ t("Buy Now") }} </AText>
							</AButton>
						</div>
					</div>

					<ADivider :bg-color="DIVIDER_COLOR" />
					<template v-if="isLogin">
						<NuxtLink
							v-if="isScratchCards"
							to="/scratch-cards/"
							class="aside-menu-link"
							data-tid="header-scratch-cards"
						>
							<NuxtIcon name="menu/scratch-cards" filled class="icon-yellow" />
							<AText :size="16" :modifiers="['medium']" class="text-tertiary-80">{{
								SKIN_CAMP ? "Scratch Camp" : t("Lucky Scratch")
							}}</AText>
							<ABadge class="badge-wow" variant="info" autosize background="var(--primary-50)">
								<AText class="text-neutral-100" :size="12" :modifiers="['uppercase', 'medium']">{{ t("New") }}</AText>
							</ABadge>
						</NuxtLink>
						<div v-if="isBingoLottery" class="aside-menu-link" data-tid="header-bingo-game" @click="handleBingoOpen()">
							<NuxtIcon name="menu/bingo" filled class="icon-yellow" />
							<AText :size="16" :modifiers="['medium']" class="text-tertiary-80">{{ t("Bingo Game") }}</AText>
							<ABadge class="badge-wow" variant="info" autosize background="var(--primary-50)">
								<AText class="text-neutral-100" :size="12" :modifiers="['uppercase', 'medium']">{{ t("Wow") }}</AText>
							</ABadge>
						</div>
						<NuxtLink v-if="isCoinDrops" to="/coin-drops/" class="aside-menu-link" data-tid="header-coin-drops">
							<NuxtIcon name="menu/gift" filled class="icon-yellow" />
							<AText :size="16" :modifiers="['medium']" class="text-tertiary-80">{{ t("Coin Drops") }}</AText>
							<ABadge class="badge-wow" variant="info" autosize background="var(--primary-50)">
								<AText class="text-neutral-100" :size="12" :modifiers="['uppercase', 'medium']">{{ t("Wow") }}</AText>
							</ABadge>
						</NuxtLink>
						<template v-if="isDepositStreak">
							<div
								v-if="prizeVaultIsActive"
								class="aside-menu-link"
								data-tid="header-prize-vault"
								@click="handlePrizeVaultOpen()"
							>
								<NuxtIcon name="menu/vault" filled class="icon-yellow" />
								<AText :size="16" :modifiers="['medium']" class="text-tertiary-80">
									{{ t("prizeVault.menu.title") }}
								</AText>
								<ABadge class="badge-wow" variant="info" autosize background="var(--primary-50)">
									<AText class="text-neutral-100" :size="12" :modifiers="['uppercase', 'medium']">
										{{ t("prizeVault.menu.badge") }}
									</AText>
								</ABadge>
							</div>
							<NuxtLink
								v-else
								to="/wheel-of-wins/"
								class="aside-menu-link"
								data-tid="header-wheel-of-wins"
								@click="handleWheelOfWinsOpen()"
							>
								<NuxtIcon name="menu/wheel-of-wins" filled class="icon-yellow" />
								<AText :size="16" :modifiers="['medium']" class="text-tertiary-80">{{ t("Wheel of Wins") }}</AText>
								<ABadge class="badge-wow" variant="info" autosize background="var(--primary-50)">
									<AText class="text-neutral-100" :size="12" :modifiers="['uppercase', 'medium']">{{ t("Top") }}</AText>
								</ABadge>
							</NuxtLink>
						</template>

						<NuxtLink
							to="/promotions/"
							class="aside-menu-link"
							data-tid="header-promotions"
							@click="handlePromoClick()"
						>
							<NuxtIcon name="menu/gift" filled />
							<AText :size="16" :modifiers="['medium']" class="text-neutral-70">
								{{ t("Promotions") }}
							</AText>
						</NuxtLink>
						<NuxtLink to="/issues/popular-games/" class="aside-menu-link" data-tid="header-games">
							<NuxtIcon name="menu/cherry" filled />
							<AText :size="16" :modifiers="['medium']" class="text-neutral-70">
								{{ t("Games") }}
							</AText>
						</NuxtLink>
						<NuxtLink to="/collections/" class="aside-menu-link" data-tid="header-collections">
							<NuxtIcon name="menu/collections" filled />
							<AText :size="16" :modifiers="['medium']" class="text-neutral-70">
								{{ t("Collections") }}
							</AText>
						</NuxtLink>
						<NuxtLink to="/tournaments/" class="aside-menu-link" data-tid="header-tournaments">
							<NuxtIcon name="menu/tournament" filled />
							<AText :size="16" :modifiers="['medium']" class="text-neutral-70">
								{{ t("Daily Tournaments") }}
							</AText>
							<ABadge
								v-if="isShowBadgeActiveRaces"
								background="var(--gradient-g-3)"
								:size="14"
								variant="info"
								class="counter"
							>
								<AText class="text-neutral-10" :size="9" :modifiers="['bold', 'condensed']" as="div">1</AText>
							</ABadge>
						</NuxtLink>
						<NuxtLink to="/referral/" class="aside-menu-link" data-tid="header-referral">
							<NuxtIcon name="menu/invite-friends" filled />
							<AText :size="16" :modifiers="['medium']" class="text-neutral-70">
								{{ t("Refer a friend") }}
							</AText>
							<ABadge class="bonus-badge" variant="info" autosize background="var(--cordoba)">
								<NuxtIcon name="20/entries" class="badge badge-icon" filled />
								<AText :size="12" class="badge">2000</AText>
							</ABadge>
						</NuxtLink>
						<ADivider :bg-color="DIVIDER_COLOR" />
						<NuxtLink to="/game/" class="aside-menu-link" data-tid="header-account">
							<NuxtIcon name="menu/account" filled />
							<AText :size="16" :modifiers="['medium']" class="text-neutral-70">
								{{ t("Account") }}
							</AText>
						</NuxtLink>
					</template>

					<div
						v-if="isLogin && isShowRedeem"
						class="aside-menu-link"
						data-tid="header-redeem"
						@click="handleRedeemClick"
					>
						<NuxtIcon name="menu/redeem" filled />
						<AText :size="16" class="text-neutral-70">
							{{ t("Redeem") }}
						</AText>
					</div>

					<template v-if="isLogin">
						<NuxtLink to="/how-it-works/" class="aside-menu-link" data-tid="header-how-it-works">
							<NuxtIcon name="menu/info" filled />
							<AText :size="16" :modifiers="['medium']" class="text-neutral-70">
								{{ t("How it works") }}
							</AText>
						</NuxtLink>
						<NuxtLink
							v-if="isShowAffiliate"
							to="/affiliates/partners-account"
							class="aside-menu-link"
							data-tid="header-partners-account"
						>
							<NuxtIcon name="menu/partner-account" filled />
							<AText :size="16" :modifiers="['medium']" class="text-neutral-70">
								{{ t("Partner’s account") }}
							</AText>
						</NuxtLink>
						<NuxtLink v-else to="/affiliates/" class="aside-menu-link" data-tid="header-affiliates">
							<NuxtIcon name="menu/affiliate" filled />
							<AText :size="16" :modifiers="['medium']" class="text-neutral-70">
								{{ t("Affiliate Program") }}
							</AText>
						</NuxtLink>
					</template>
					<ADivider :bg-color="DIVIDER_COLOR" />
					<div class="aside-menu-link" data-tid="header-support" @click.stop="handleOpenSupportClick">
						<NuxtIcon name="menu/support" filled />
						<AText class="text-neutral-70" :size="16" :modifiers="['medium']">{{ t("Support") }}</AText>
					</div>
					<div v-if="isLogin" class="aside-menu-link" data-tid="header-logout" @click="handlelogOutClick">
						<NuxtIcon name="menu/log-out" filled />
						<AText class="text-neutral-70" :size="16" :modifiers="['medium']">{{ t("Log out") }}</AText>
					</div>
					<ADivider :bg-color="DIVIDER_COLOR" />
					<NuxtLink
						to="mailto:support@nolimitcoins.com"
						external
						class="aside-menu-link"
						data-tid="header-support-email"
					>
						<NuxtIcon name="menu/mail" filled class="icon-support" />
						<AText class="text-neutral-40" :size="12" :modifiers="['uppercase', 'bold']">
							support@nolimitcoins.com
						</AText>
					</NuxtLink>
					<NuxtLink
						class="icon-trustpilot"
						data-tid="header-trustpilot"
						to="https://www.trustpilot.com/evaluate/nolimitcoins.com?stars=5"
						external
						target="_blank"
					>
						<NuxtImg
							src="/nuxt-img/footer/btn_trustpilot.svg"
							loading="lazy"
							alt="trustpilot"
							width="110"
							height="40"
						/>
					</NuxtLink>
					<ADivider :bg-color="DIVIDER_COLOR" />
					<div class="social-btns">
						<NuxtLink to="https://www.facebook.com/NoLimitCoinsGames" external target="_blank">
							<AButton variant="ghost" size="md">
								<NuxtIcon name="menu/facebook" filled />
							</AButton>
						</NuxtLink>
						<NuxtLink to="https://x.com/NoLimitCoinsCom" external target="_blank">
							<AButton variant="ghost" size="md">
								<NuxtIcon name="menu/xcom" filled />
							</AButton>
						</NuxtLink>
					</div>
				</nav>
			</div>
		</aside>
	</Transition>
</template>

<style scoped lang="scss">
$transition: all 250ms ease-in-out;
.sidebar-overlay {
	z-index: 10004;
	display: block;
	@include media-breakpoint-down(lg) {
		z-index: 1998;
	}
}
aside {
	z-index: 10005;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	background: var(--neutral-10);
	max-width: 100%;
	width: 360px;
	height: 100%;
	padding: 24px 16px;
	overflow-y: auto;
	scrollbar-width: none;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	@include media-breakpoint-down(lg) {
		z-index: 1999;
		width: 100%;
		padding: 80px 16px 90px;
		&.header-padding {
			padding-top: 150px;
		}
	}
	.aside-menu {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
	}
	.banners-mini {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 8px;
	}
	.aside-menu-banner {
		cursor: pointer;
		--a-button-default-padding: 8px 12px;
		--a-button-outline-padding: 8px 12px;
		padding: 2px 8px;
		background: var(--neutral-5);
		border-radius: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 8px;
		height: 52px;
		&.wheel {
			background: linear-gradient(90deg, #223c80 0%, rgba(34, 60, 128, 0) 100%);
			:deep(.box.animation-active) {
				.box-spin-default,
				.box-spin-neon {
					animation: none;
					margin-top: -3px;
				}
			}
			.disabled {
				--a-button-disabled-background: var(--neutral-40);
				--a-button-disabled-hover-background: var(--neutral-40);
				--a-button-size-x-small-height: 22px;
				--a-button-size-small-border-radius: 4px;
				padding: 2px 4px;
				width: 100px;
			}
		}
		&.bonus {
			background: v-bind(promoOfferBackground);
		}
		.icon {
			max-width: 44px;
			height: auto;
		}
		.inner-content {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
		}
	}
	.aside-menu-link {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 8px;
		transition: $transition;
		cursor: pointer;
		color: var(--neutral-70);
		font-size: 16px;
		font-weight: 500;
		&:hover {
			opacity: 0.6;
		}
		.nuxt-icon {
			font-size: 16px;
			color: var(--neutral-100);
			&.icon-yellow {
				color: var(--tertiary-80);
			}
		}
		.icon-support.nuxt-icon {
			color: var(--neutral-40);
		}
		.counter {
			--a-badge-info-radius: 100%;
			--a-badge-info-height: 14px;
		}
	}
	.aside-menu {
		.router-link-active span:not(.badge) {
			color: var(--cannes);
			:deep(svg path) {
				fill: var(--cannes);
			}
		}
		.badge-wow {
			padding: 4px;
		}
		.bonus-badge {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 4px;
			padding: 2px 5px;
			color: var(--changchun);

			.badge-icon {
				font-size: 15px;
			}

			&.info {
				box-shadow: none;
			}

			&:deep(.nuxt-icon) {
				margin-right: initial;
			}
		}
	}
}
.social-btns {
	display: flex;
	align-items: center;
	gap: 12px;
	a {
		width: 100%;
	}
	button {
		transition: $transition;
		background: var(--neutral-15);
		width: inherit;
		&:hover {
			background: var(--neutral-40);
		}
		.nuxt-icon {
			font-size: 24px;
		}
	}
}
.fade-enter-active,
.fade-leave-active {
	transition: $transition;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.slide-enter-active {
	transition: $transition;
	transform: translateX(-100%);
}
.slide-enter-to {
	transform: translateX(0%);
}
.slide-leave-active {
	transition: $transition;
	transform: translateX(0%);
}
.slide-leave-to {
	transform: translateX(-100%);
}
</style>
